<script>
import debounce from 'lodash-es/debounce'
import { ref, nextTick } from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import SearchItem from '/~/components/app/search/search-item.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useSearch } from '/~/composables/search'
import { useUI } from '/~/composables/ui'

export default {
  name: 'app-search',
  components: {
    SearchItem,
    BaseInput,
    BaseButton,
  },
  mixins: [clickaway],
  setup() {
    const { lockKeyboardAccessArea, unlockKeyboardAccessArea, headerHeight } =
      useUI()
    const {
      searchResults,
      search,
      clearSearch,
      isSearchVisible,
      isSearchEmpty,
    } = useSearch()

    const rootElement = ref(null)

    return {
      ...clickaway.setup?.(...arguments),
      searchResults,
      search,
      clearSearch,
      isSearchVisible,
      isSearchEmpty,
      rootElement,
      lockKeyboardAccessArea,
      unlockKeyboardAccessArea,
      ui,
      headerHeight,
    }
  },
  data() {
    return {
      searchInput: '',
      shadow: false,
    }
  },
  watch: {
    async isSearchVisible(visible) {
      if (visible) {
        const previousActiveElement = document.activeElement

        await this.show()
        nextTick(() => {
          this.lockKeyboardAccessArea({
            id: 'app-search',
            rootElement: this.rootElement,
            previousActiveElement,
            focusElement: false,
            delay: 200,
          })
        })
      } else {
        await this.unlockKeyboardAccessArea('app-search')
        this.hide()
      }
    },
  },
  methods: {
    onInput: debounce(function (inputValue) {
      this.search(inputValue)
    }, 300),
    focus() {
      const input = this.$refs.inputContainer

      input?.querySelector('input').focus()
    },
    blur() {
      const input = this.$refs.inputContainer

      input?.querySelector('input').blur()
    },
    clear() {
      this.searchInput = ''
      this.clearSearch()
    },
    async show() {
      ui.lockViewport()
      await nextTick()

      this.focus()
    },
    hide() {
      ui.unlockViewport()
      this.blur()
    },
    close() {
      this.clear()
      this.isSearchVisible = false
    },
    handleScroll({ target: { scrollTop } }) {
      this.shadow = scrollTop > 0
    },
    touchStart() {
      this.blur()
    },
  },
}
</script>

<template>
  <transition name="fade">
    <div
      v-if="isSearchVisible"
      ref="rootElement"
      class="fixed left-0 top-0 z-popup flex h-full w-full flex-col overflow-hidden"
      :style="{ 'padding-top': `${headerHeight}px` }"
      @keyup.esc="close"
    >
      <div class="absolute h-full w-full bg-overlay" @click="close" />
      <div class="relative z-1 flex max-h-full w-full flex-col bg-light">
        <div
          class="py-5 sm:pb-[30px] sm:pt-2.5"
          :class="[shadow && 'z-1 shadow transition-shadow duration-100']"
        >
          <div
            class="mx-auto flex w-full max-w-screen-md flex-nowrap px-5 sm:px-2.5"
          >
            <div
              ref="inputContainer"
              class="relative mr-2.5 flex flex-auto items-center sm:mr-5"
            >
              <base-input
                v-model="searchInput"
                class="m-0 p-0"
                icon="plain/search"
                placeholder="Search"
                name="search"
                icon-plain
                clearable
                nolabel
                @input="onInput"
              />
            </div>
            <base-button
              icon="base/close"
              size="md"
              :padding="5"
              alt="close"
              @click="close"
            />
          </div>
        </div>
        <div
          v-if="searchInput"
          class="overflow-y-auto"
          @scroll="handleScroll"
          @touchstart="touchStart"
        >
          <div class="mx-auto w-full max-w-screen-md px-2.5">
            <span
              v-if="isSearchEmpty"
              class="flex justify-center py-6"
              aria-live="assertive"
              aria-atomic="true"
            >
              No results
            </span>

            <div
              v-else-if="searchResults.length > 0"
              class="space-y-[30px] py-2.5"
              data-cy="search-results"
            >
              <template v-for="result in searchResults">
                <div v-if="result.data.length > 0" :key="result.label">
                  <h2 class="flex items-center px-2.5 py-2.5 text-xl font-bold">
                    {{ result.label }}
                  </h2>
                  <ul>
                    <li
                      v-for="(item, idx) in result.data"
                      :key="`${item.id}-${idx}`"
                    >
                      <search-item :data="item" @close="close" />
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
